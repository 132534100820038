import React from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../components/Layout';
import ArtPieceGallery from '../components/ArtPieceGallery';
import {getArtPiecesFromQueryResult} from '../helpers';

const PaintingsPage = ({data}) => (
    <>
        <Helmet>
            <title>Paintings : {data.site.siteMetadata.title}</title>
            <meta
                name="description"
                content={`Paintings : ${data.site.siteMetadata.description}`}
            />
            <meta
                name="og:title"
                content={`Paintings : ${data.site.siteMetadata.title}`}
            />
            <meta
                name="og:description"
                content={`Paintings : ${data.site.siteMetadata.description}`}
            />
            <meta
                name="og:url"
                content={`${data.site.siteMetadata.url}/paintings`}
            />
            <meta name="og:type" content="website" />
        </Helmet>
        <Layout>
            <ArtPieceGallery {...getArtPiecesFromQueryResult(data)} />
        </Layout>
    </>
);

export default PaintingsPage;

export const query = graphql`
query {
  site {
    ...SiteMetadata
  }
  allContentfulPiece(filter: {category: {eq: "Paintings"}}, sort: {order: DESC, fields: year}) {
    edges {
      node {
        ...ArtPieceMetadata
        picture {
          id
          preview: fixed(width: 350, height: 350, resizingBehavior: FILL) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          full: fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
}
`;
